import './App.css';
import Button from './Button';
import namaste from './assets/img/namaste.png';

function App() {
    return (
        <div className="App">
            <header>
                <h1>BaiDekho</h1>
                <div className='right'>
                    <Button text={'Login'} />
                    <Button text={'About Us'} />
                </div>
            </header>

            <div className='main'>
                <div className='top_text'>
                    <div className='text'>
                        <h1>Welcome!</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vehicula magna felis, non dignissim lectus bibendum a. Proin ornare dolor sollicitudin ligula accumsan, non gravida eros semper. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Phasellus ac justo nunc. Mauris ut ante ut dui scelerisque eleifend. Pellentesque viverra a metus in ullamcorper. Etiam non sagittis velit.</p>
                    </div>
                    <div className='img'><img src={namaste} alt='namaste' /></div>
                </div>
            </div>
        </div>
    );
}

export default App;
